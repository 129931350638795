import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PreferencesService } from '@proman/services/preferences.service';
import { AuthService } from '@proman/services/auth.service';
import { Entity } from '@proman/services/entity.service';
import { AppLoader } from '@proman/services/app-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { CardLoginListenerService } from '../services/card-login-listener.service';
import { ActivatedRoute } from '@angular/router';
import { CONFIG } from '@proman/config';
import { RequestService } from '@proman/services/request.service';
import { isChef, isLocalhost, uniqBy } from '@proman/utils';
import { PublicSystemOptions } from '@proman/interfaces/entity-interfaces';
import { COOKIE_AUTHORIZATION, CookiesService } from '@proman/services/cookies.service';
import { UserEntityInterface } from '@proman/resources/user';
import { LastUser, LoginProject } from '@proman/interfaces/object-interfaces';
import { Store } from '@ngrx/store';
import { getPublicSystemOptions } from '@proman/store/system-options';
import { ToastService } from '@proman/services/toast.service';
import { PromanStateService } from '../services/proman-state.service';
import { ImagePathService } from '@proman/services/image-path.service';

@Component({
    selector: 'pm-login',
    template: `
        <div class="Login"
             fxLayout="column"
             fxLayoutAlign="center center"
             fxFlex="1 1 auto">
            <div fxLayout="column"
                 style="min-width: 320px; max-width: 380px;">
                <pm-language-switch></pm-language-switch>
                <div style="min-height: 5px;">
                    <mat-progress-bar *ngIf="isLogin"
                                      mode="indeterminate"></mat-progress-bar>
                </div>
                <div class="Login-wrapper"
                     #element>
                  <div fxLayout="column" fxLayoutAlign="center center">
                    @switch (!!imgUrl) {
                      @case (true) {
                        <img [alt]="'company_logo' | translate"
                             class="Login-logo"
                             (dblclick)="clearUserHistory()"
                             style="width: 90px;"
                             [src]="imgUrl">
                      }
                      @default {
                        <img class="Login-logo"
                             style="width: 90px;"
                             (dblclick)="clearUserHistory()"
                             [alt]="'company_logo' | translate"
                             [src]="'/assets/images/smarton_logo_notext.png'">
                      }
                    }
                  </div>
                    <div fxLayout="column" *ngIf="!isChangePassword">
                        <mat-chip-list *ngIf="users?.length" style="padding: 32px 0 8px 0;">
                            <mat-chip *ngFor="let user of users"
                                      [selected]="credentials._username === user.username"
                                      (click)="setUser(user)"
                                      color="accent">{{ user.name }}
                            </mat-chip>
                        </mat-chip-list>
                        <pm-login-form [credentials]="credentials"
                                       (onSubmit)="handleLogin($event)"></pm-login-form>
                        <div style="min-height: 32px;">
<!--                        <p class="ValidationError" *ngIf="error"> {{ error | translate }} </p>-->
                            <p class="Success" *ngIf="twoFactorLoginEnabled"> {{ 'waiting_two_factor_authentication' | translate }} </p>
                        </div>
                    </div>
                    <div fxLayout="column" *ngIf="isChangePassword">
                        <pm-warning style="margin-bottom: 16px;" [preventClose]="true">{{ 'must_change_password' | translate }}</pm-warning>
                        <pm-change-password-form (onSubmit)="changePassword($event)"></pm-change-password-form>
                    </div>
                    <div fxLayout="column">
                        <div class="Login-card"
                             fxLayout="row"
                             fxLayoutAlign="center center"
                             *ngIf="isRfid">
                            <fa name="rss"></fa>
                            <p>{{ 'login_using_card' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `
})

export class LoginComponent implements OnInit {
    @Input() publicSystemOptions: PublicSystemOptions;
    @ViewChild('element', { static: true }) element: ElementRef;

    isChef: boolean = isChef();
    isLocal: boolean = isLocalhost();
    isChangePassword: boolean;
    isLogin: boolean;
    error: any;
    credentials: { _username?: string; _password?: string } = {};
    isRfid: boolean;
    users: LastUser[];
    userEntity: UserEntityInterface;
    twoFactorLoginEnabled: boolean = false;
    imgUrl: string = null;

    constructor(
        private Preferences: PreferencesService,
        private Auth: AuthService,
        private Entity: Entity,
        private AppLoader: AppLoader,
        private store: Store<unknown>,
        private Translate: TranslateService,
        private CardLogin: CardLoginListenerService,
        private route: ActivatedRoute,
        private Request: RequestService,
        private Cookies: CookiesService,
        private PromanState: PromanStateService,
        private ImagePath: ImagePathService,
        private Toast: ToastService,
    ) {
        this.store.select(getPublicSystemOptions)
            .subscribe((value) => {
                this.publicSystemOptions = value;
                if (value.logo) this.imgUrl = this.ImagePath.getFile(value.logo);
                if (value.loginToShopRedirectToUrl && value.loginToShopRedirectFromUrl) {
                  console.log(value, window.location.href);
                  if (window.location.href.includes(value.loginToShopRedirectFromUrl)
                    && !window.location.href.includes(`${value.loginToShopRedirectFromUrl}/shop`)) window.location.href = value.loginToShopRedirectToUrl;
                }
                this.Translate.use(this.Preferences?.language() || this.publicSystemOptions?.defaultLanguage);
                if (value.seoKeywords?.length > 0) {
                  const script: HTMLMetaElement = document.createElement('meta');
                  script.name = 'keywords';
                  script.content = value.seoKeywords;
                  document.head.insertBefore(script, document.head.firstChild);
                }
            });
        this.userEntity = this.Entity.get('user') as UserEntityInterface;
        this.credentials = { _username: '', _password: '' };
        this.AppLoader.remove();

        this.handleQueryParams(this.route.snapshot.queryParams);
    }

    ngOnInit() {
        this.isRfid = this.publicSystemOptions?.isCardLoginEnabled;
        this.users = (this.Preferences?.users() || []).filter((user: unknown) => typeof user === 'object');

        if (this.isRfid) {
            this.CardLogin.suspend();
            this.CardLogin.start();
        }

        this.setActiveUser();
    }

    setUser(user: LastUser) {
        this.setLastUsedUsername(user);
        this.credentials._username = user.username;

        this.focusPasswordInput();
    }

    setActiveUser() {

        if (this.users?.length) {
            this.credentials = { _username: this.users[0].username, _password: '' };

            this.focusPasswordInput();
        }

    }

    focusPasswordInput() {
        const passInputElement = this.element.nativeElement.querySelector('input[type="password"]');

        if (passInputElement) passInputElement.focus();

    }

    setLastUsedUsername(user: LastUser) {
        const users = this.users.slice();

        users.unshift(user);

        this.Preferences.users(uniqBy(users, 'name').slice(0, 3));
    }

    handleLogin(credentials: any) {

        if (this.isLogin) return;

        // only first 14 characters of the message string
        const errors = ['IP not allowed', 'Bad credential', 'Login disabled', 'Empty password'];
        this.error = null;

        if (credentials._username.length === 0 || credentials._password.length === 0) return;

        this.isLogin = true;

        this.Auth
            .login(credentials, LoginProject.FRONTEND, true).then((response: any) => {
              this.setLastUsedUsername({ name: response.person.firstName, username: credentials._username });

              if (response?.twoFactorLoginEnabled) {
                this.twoFactorLoginEnabled = true;
                this.handleTwoFactorLoginEnabled(response, credentials);
              }

              if (response?.mustChangePassword) {
                this.isChangePassword = true;
                this.handleResponse(response);
              } else {
                this.PromanState.go(true);
              }
            })
            .catch((response: any) => {
                const error = response.error;
                this.handleResponse(error?.message ?? error?.error ?? error);
                if (errors.includes(this.error?.substring(0, 14))) this.Toast.pop('info', this.error);

                this.isLogin = false;
            });
    }

    handleTwoFactorLoginEnabled(response: any, credentials: any) {
        this.Request.post(`${CONFIG.api}user/two_factor_confirmation`, { person: response.person.id})
            .then((response: any) => {
                if (response.message === 'Confirmed') {
                    this.Auth.login(credentials, LoginProject.FRONTEND, true, false);
                }
            })
    }

    handleResponse(error: any) {
        this.error = error;

        this.isLogin = false;
        this.credentials._password = '';
    }

    changePassword(data: any) {
        this.userEntity
            .changePassword(data)
            .then(this.setPasswordChanged);
    }

    setPasswordChanged = () => {
        this.error = null;
        this.isChangePassword = false;

        this.Auth.handleAuthorisation();
        this.PromanState.go(true);
    };

    handleQueryParams = (params: any) => {
        if (params.companyToken) {
            this.Cookies.set(COOKIE_AUTHORIZATION, params.companyToken);
            const bareToken = params.companyToken;

            this.Request
                .loadUser()
                .then((response: any) => {
                    this.Auth.switchToken(response.token, response.username);
                }).then(() => {
                    this.PromanState.go(true);
            })
                .catch((response: any) => {
                    const error = response.error;

                    this.handleResponse(error && error.message || error);
                });
        }

    };

    clearUserHistory() {
      this.users = [];
      this.Preferences?.users([]);
    }

}

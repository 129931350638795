import { Inject, Injectable } from '@angular/core';
import { CONFIG } from '../config';
import { PreferencesService } from '@proman/services/preferences.service';
import { WebsocketService } from '@proman/services/websocket.service';
import { AuthService } from '@proman/services/auth.service';
import { RequestService } from '@proman/services/request.service';
import { Router } from '@angular/router';
import { COOKIE_AUTHORIZATION, CookiesService } from '@proman/services/cookies.service';
import { Store } from '@ngrx/store';
import { userLogout } from '@proman/store/curr-user/curr-user.actions';

@Injectable()
export class LogoutService {

    constructor(
        private Request: RequestService,
        private Router: Router,
        private Auth: AuthService,
        private Prefs: PreferencesService,
        private Websocket: WebsocketService,
        private Cookies: CookiesService,
        @Inject(Store) private store: Store,
    ) {

    }

    logout = (skipRedirect?: boolean): Promise<void> => {
        return new Promise((resolve) => {
            let tokens = this.Prefs.tokens();

            for (let username in tokens) {

                if (tokens[username] === this.Auth.getToken()) {
                    delete tokens[username];
                    break;
                }

            }

            this.Request.post(CONFIG.api + 'user/logout')
                .then(() => {
                    this.Websocket.endSession();
                    if (!skipRedirect) this.Router.navigate(['login']);
                    this.Prefs.tokens(tokens);
                    this.Auth.clearToken();
                    this.Cookies.remove(COOKIE_AUTHORIZATION);
                    this.store.dispatch(userLogout());
                    resolve();
                });
        })
    };

}

import { Injectable, Injector } from '@angular/core';
import { ToastService } from '@proman/services/toast.service';
import { Entity } from '@proman/services/entity.service';
import { LogoutService } from '@proman/services/logout.service';
import { AuthService } from '@proman/services/auth.service';
import $ from 'jquery';
import { CurrUser, LoginProject } from '@proman/interfaces/object-interfaces';
import { Store } from '@ngrx/store';
import { UserEntityInterface } from '@proman/resources/user';
import { getCurrUser } from '@proman/store/curr-user';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';

@Injectable()
export class CardLoginListenerService {
    timer: any = null;
    keyLength: number = 10;
    buffer: any = [];
    logged: boolean;
    userEntity: UserEntityInterface;
    currUser: CurrUser;

    constructor(
        private Entity: Entity,
        private Auth: AuthService,
        private Injector: Injector,
        private Toast: ToastService,
        private Logout: LogoutService,
        private PromanState: PromanStateService,
        private store: Store,
    ) {
        this.userEntity = this.Entity.get('user');
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
    }

    start = () => {
        let threshold = 30; // ms

        $('body').on('keydown.cardlogin', (event) => {
            let allowedKeyCodes;

            if (this.timer) {
                clearTimeout(this.timer);
            }

            this.timer = setTimeout(this.notify, threshold);
            // allowedKeyCodes = return, 0-9, =
            allowedKeyCodes = [13, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 187];
            if (allowedKeyCodes.indexOf(event.keyCode) >= 0) {
                this.buffer.push(String.fromCharCode(event.keyCode));
            } else {
                this.buffer.length = 0;
            }
        });
    };

    notify = () => {
        let input = this.buffer.join('').trim();

        clearTimeout(this.timer);

        this.buffer.length = 0;

        if (input.length === this.keyLength) {

            this.handleCard(input);

            this.logged = true;

        }

    };

    handleCard = (token: string, project: LoginProject = LoginProject.FRONTEND) => {
        let disabled = () => this.Toast.pop('error', 'this_function_is_disabled');

        let credentials = { _token: token, project };

        if (this.currUser && this.Auth.getToken()) {
            let _username = this.currUser.username;

            this.userEntity
                .checkCardOwner(Object.assign({_username}, credentials))
                .then((response: any) => {
                    let skipRedirect = !response.result;

                    this.Auth.clearToken();

                    this.Logout.logout(skipRedirect);

                    if (skipRedirect) {
                        this.Auth.login(credentials);

                    }

                }, (error: any) => {
                    if (error.status === 401) {
                        this.Auth.clearToken();
                        return this.handleCard(token);
                    } else {
                        disabled();
                    }
                });

        } else {
            this.Auth
                .login(credentials)
                .then(() => {
                  this.PromanState.go(true);
                })
                .catch(disabled);

        }

    };

    suspend = () => $('body').off('keydown.cardlogin');

    isCardLogin = async () => {
        let result: any;

        await setTimeout(() => {

            if (this.logged) {
                this.logged = false;
                result = Promise.resolve();

            } else {
                result = Promise.reject('error');

            }

        }, 350);

        return result;
    };
}
